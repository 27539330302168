import React, { useContext, useEffect, useState } from 'react';
import { Page, Text, Document, View, StyleSheet, Image, PDFViewer, Font, PDFDownloadLink, BlobProvider, usePDF } from '@react-pdf/renderer';
import QRCode from 'qrcode'
import { store } from '../../store'
import Button from '@material-ui/core/Button'

Font.register({
    family: 'Roboto',
    src: '/RobotoCondensed-Regular.ttf'
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontFamily: 'Roboto',
        fontSize: 14,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    header: {
        fontSize: 14,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    w50: {
        width: '50%',
        margin: 12,
    },
    image: {
        width: 120
    },
});

const Certificate = () => {
    const [fileurl, setFileurl] = useState(null)
    const [imageURL, setImageURL] = useState('/blank.jpg')
    const globalState = useContext(store);
    const { state, dispatch } = globalState;

    useEffect(() => {
        if(state.hash!=null){
            handleGenerateQR()
        }
    }, [state.hash])

    const handleGenerateQR = async () => {
        try {
          const response = await QRCode.toDataURL(`https://pcr.obllik.ck.ua/cert/${state.hash}`)
          setImageURL(response)
        } catch (error) {
          console.log(error)
        }
    }

    const isDate = (data) => {
        return !isNaN(data)
    }

    const addZero = (n) => {
        return n<10? `0${n}`:`${n}`;
    }

    const MyDocument = () => (
        <Document>
          <Page size="A4" style={styles.body}>
            <Text style={styles.title}>
                MoH of UKRAINE / МОЗ УКРАЇНИ
            </Text>
            <View style={styles.row}>
                <Text style={[{
                    width: "50%",
                    margin: 12,
                    fontSize: 12
                }]}>
                    COMMUNAL NONPROFIT ENTERPRISE “CHERKASY REGIONAL HOSPITAL OF CHERKASY OBLAST COUNCIL” Certificate MZ №014463 Expiry date 26.05.2022 3 Mendelieieva St., 18009 Cherkasy, Ukraine
                </Text>
                <Text style={[{
                    width: "50%",
                    margin: 12,
                    fontSize: 12
                }]}>
                    КОМУНАЛЬНЕ НЕКОМЕРЦІИНЕ ПІДПРИЄМСТВО «ЧЕРКАСЬКА ОБЛАСНА ЛІКАРНЯ ЧЕРКАСЬКОЇ ОБЛАСНОЇ РАДИ» Сертифікат МЗ № 014463 Дійсний 26.05.2022 вул. Менделєєва, 3, 18009 Черкаси, Україна
                </Text>
            </View>
            <View style={[{
                flexDirection: 'row',
                justifyContent: "space-between",
                alignItems: "center"
            }]}>
                <View style={[{
                    width: '50%',
                    alignItems: 'center'
                }]}>
                    <Text>
                        Tel +380(472) 37-62-92
                    </Text>
                    <Text>
                        E-mail: oblkan@zdrav.ck.ua
                    </Text>
                    <Text>
                        Tel. Lab. +380(472) 33-45-32
                    </Text>
                    <Text>
                        E-mail: obllabpcr@ukr.net
                    </Text>
                </View>
                <View style={[{
                    width: '50%',
                    alignItems: 'center'
                }]}>
                    <Image
                        style={styles.image}
                        src={imageURL}
                    />
                </View>
            </View>
            <Text style={[{
                backgroundColor: 'grey',
                textAlign: 'center',
                color: 'white',
                marginTop: 12,
                padding: 5
            }]}>
                EXAMINATION RESULT / РЕЗУЛЬТАТ ДОСЛІДЖЕННЯ
            </Text>
            <Text style={[{
                textAlign: 'center',
                marginTop: 12,
            }]}>
                № {state.fieldNumber}
            </Text>
            <Text style={[{
                textAlign: 'center'
            }]}>    
                Дата та час реєстрації
            </Text>
            <Text style={[{
                textAlign: 'center',
                fontWeight: 'bold'
            }]}>
                Registration Date/time: { state.fieldDateRegistrationTXT }
            </Text>
            <View style={[{
                marginTop: 12,
                flexDirection: 'row',
                justifyContent: 'space-between'
            }]}>
                <View style={[{
                    marginRight: 18
                }]}>
                    <Text className='uppercase'>
                        Пацієнт: { state.fieldFullNameUKR }
                    </Text>
                    <Text className='uppercase'>
                        Patient: { state.fieldFullNameENG }
                    </Text>
                    <Text>
                        Passport: { state.fieldPassport }
                    </Text>
                </View>
                <View style={[{
                    marginRight: 24
                }]}>
                    <Text>Дата народження: { state.fieldBirthdayTXT }</Text>
                    <Text>Data of birth: { state.fieldBirthdayTXT }</Text>
                </View>
                <View>
                    <Text>Стать: { state.fieldGenderUKR }.</Text>
                    <Text>Gender: { state.fieldGender} </Text>
                </View>
            </View>
            <View style={[{
                marginTop: 12
            }]}>
                <Text>Матеріал: Назо- або орофарингеальний мазок</Text>
                <Text>Material: Scraping of epithelial cells from the oropharynx and nasopharynx</Text>
            </View>
            <View style={[{
                marginTop: 12
            }]}>
                <Text>Визначенння РНК вірусу COVID - 2019 (SARS-CoV-2) методом ПЛР: { state.fieldDetectionUKR }</Text>
                <Text>Detection RNA of coronavirus COV1D-19(SARS-CoV-2) PCR TESTING: { state.fieldDetection }</Text>
            </View>
            <View style={[{
                marginTop: 12
            }]}>
                <Text>Дата/Час виконання: { state.fieldDateSamplingTXT }</Text>
                <Text>Date of sampling/Time of sampling: { state.fieldDateSamplingTXT }</Text>
            </View>
            <View style = {[{
                flexDirection: 'row',
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 10
            }]}>
                <View>
                    <Text>бактеріолог</Text>
                    <Text>bacteriologist</Text>
                    <Text style = {[{
                        marginTop: 20
                    }]}>
                        Validator/валідатор
                    </Text>
                </View>
                
                <View style={[{
                    alignItems: 'center'
                }]}>
                    <Image
                        style={styles.image}
                        src="/stamp.jpg"
                    />
                </View>

                <View style={[{
                    alignItems: 'center'
                }]}>
                    <Image
                        style={[{
                            width: 70
                        }]}
                        src="/pidp.jpg"
                    />
                </View>
                
                <View>
                    <Text>Проня B.B.</Text>
                    <Text>Pronia V.V.</Text>
                    <Text style = {[{
                        marginTop: 20
                    }]}>
                        Zabolotko A.R.
                    </Text>
                </View>
            </View>
            <Text style={[{
                backgroundColor: 'grey',
                textAlign: 'center',
                color: 'white',
                marginTop: 10,
                padding: 5
            }]}>
                The test results are not a clinical diagnosis and require medical advice
            </Text>
          </Page>
        </Document>
    );

    // const [instance, updateInstance] = usePDF({ document: MyDoc });
    
    

    // if (instance.loading) return <div>Loading ...</div>;

    // if (instance.error) return <div>Something went wrong ... update the page</div>;

    // return ReactPDF.render(<MyDocument />);

    // const Stream = async() => await renderToStream(<MyDocument />);

    const getFilename = () => {
        return `${state.fieldNumber}_${state.fieldFullNameENG}.pdf`
    }

    return (
        <div className="flex flex-col w-full">
            <PDFViewer className="h-0 md:h-screen w-full">
                <MyDocument />    
            </PDFViewer>
            <div className="flex justify-center align-middle mt-10 md:hidden">
                {/* <PDFDownloadLink 
                    className="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                    document={<MyDocument />}
                    fileName={getFilename()}
                >
                    {({ blob, url, loading, error }) => {
                        
                        return loading ? 'Loading document...' : 'Завантажити'
                    }
                    }
                </PDFDownloadLink> */}
                <BlobProvider
                    document={<MyDocument />}
                >
                    {({ blob, url, loading, error }) => {
                        
                        if (loading) {
                            return <div>Завантаження...</div>;    
                        } else {
                            
                            // console.log(blob);
                            return <Button variant="contained" color="primary" href={url} download={getFilename()}>Завантажити</Button>
                            
                            // return <Button variant="contained" color="primary" disabled>Завантажити</Button>
                        }
                                                
                    }}
                </BlobProvider>
            </div>
        </div>
    )
        
}

export default Certificate
