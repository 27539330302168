import React, { useContext, useEffect, useState } from 'react'
import { DatePicker } from "@material-ui/pickers";

import { store } from '../../store';


const DateFilter = () => {
    const [selectedDate, changeSelectedDate] = useState(null);
    const globalState = useContext(store);
    const { state, dispatch } = globalState;

    useEffect(() => {
        if (selectedDate) {
            let start = new Date(selectedDate)
            start.setHours(0,0,0,0)
            let end = new Date(selectedDate)
            end.setHours(23,59,59,999)
            dispatch({ type: `SET_DATASTART`, payload: Math.floor(start.getTime() / 1000) });
            dispatch({ type: `SET_DATAEND`, payload: Math.floor(end.getTime() / 1000) });
            dispatch({ type: `SET_SELECTEDDATE`, payload: selectedDate });
        }
    }, [selectedDate])

    return (
        <>
            <DatePicker 
                autoOk
                openTo="date"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                label="Дата внесення"
                value={state.selectedDate}
                onChange={changeSelectedDate}
            />  
        </>
    )
}

export default DateFilter
