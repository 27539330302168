import React from 'react'
import LoginForm from './LoginForm'

const Login = () => {
    return (
        <>
            <LoginForm />
        </>
    )
}

export default Login
