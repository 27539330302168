import React, { useContext, useEffect, useState } from 'react'
import hash from 'object-hash'

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import FormAdd from './FormAdd';
import Certificate from '../certificate';
import useApi from '../../api/useApi';
import { store } from '../../store';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
}));

function getSteps() {
    return ['Введення результату дослідження ', 'Попередній перегляд та друк'];
  }
  
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <FormAdd />
      case 1:
        return <Certificate />
      default:
        return 'Unknown step';
    }
  }

const Add = () => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const [ xcsrf, setXcsrf ] = useState(state.csrfToken);
    const { postPatient, postCertificate, fetchXCSRFToken } = useApi();

    useEffect(() => {
      setActiveStep(0)
      dispatch({ type: `SET_fieldFullNameUKR`, payload: '' });
      dispatch({ type: `SET_fieldFullNameENG`, payload: '' });
      dispatch({ type: `SET_fieldNumber`, payload: '' });
      dispatch({ type: `SET_fieldGender`, payload: '' });
      dispatch({ type: `SET_fieldGenderUKR`, payload: '' });
      // dispatch({ type: `SET_fieldBirthday`, payload: new Date() });
      dispatch({ type: `SET_fieldPassport`, payload: '' });
      // dispatch({ type: `SET_fieldDateRegistration`, payload: new Date() });
      // dispatch({ type: `SET_fieldDateSampling`, payload: new Date() });
      dispatch({ type: `SET_fieldDetection`, payload: '' });
      dispatch({ type: `SET_fieldDetectionUKR`, payload: '' });
    }, [])

    useEffect(() => {
        setXcsrf(state.csrfToken)
    }, [state.csrfToken])

    const handleSaveResult = async() => {

      let token = ''
      if (xcsrf==='') {
          token = await fetchXCSRFToken()

          setXcsrf(token)
      }
      let profile = await postPatient(token);
      let result = await postCertificate(token, profile.nid[0].value)
      return result
    }

    const validateForm = () => {
      
      if (state.fieldNumber === '') {
        dispatch({ type: `SET_errorFieldNumber`, payload: true });
      } else {
        dispatch({ type: `SET_errorFieldNumber`, payload: false });
      }

      if (state.fieldGenderUKR === '') {
        dispatch({ type: `SET_errorfieldGenderUKR`, payload: true });
      } else {
        dispatch({ type: `SET_errorfieldGenderUKR`, payload: false });
      }

      if (state.fieldGender === '') {
        dispatch({ type: `SET_errorfieldGender`, payload: true });
      } else {
        dispatch({ type: `SET_errorfieldGender`, payload: false });
      }

      if (state.fieldNumber==='' || state.fieldGenderUKR === '' || state.fieldGender ==='') {
        return
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      console.log('validate ');
    }

    const handleNext = () => {
        if (activeStep === 0) {
          validateForm()
        }
        if (activeStep === 1) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          handleSaveResult()
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const buttonBlock = (activeStep) => {
        
          return (
            <>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Повернутися
              </Button>
              <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
              >
                Далі
              </Button>
          </>
          )

    };

    const handleNewExamination = () => {
      setActiveStep(0);
      dispatch({ type: `SET_HASH`, payload: null });
      dispatch({ type: `SET_fieldFullNameUKR`, payload: '' });
      dispatch({ type: `SET_fieldFullNameENG`, payload: '' });
      dispatch({ type: `SET_fieldNumber`, payload: '' });
      dispatch({ type: `SET_fieldGender`, payload: '' });
      dispatch({ type: `SET_fieldGenderUKR`, payload: '' });
      dispatch({ type: `SET_fieldBirthday`, payload: new Date() });
      dispatch({ type: `SET_fieldPassport`, payload: '' });
      dispatch({ type: `SET_fieldDateRegistration`, payload: new Date() });
      dispatch({ type: `SET_fieldDateSampling`, payload: new Date() });
      dispatch({ type: `SET_fieldDetection`, payload: '' });
      dispatch({ type: `SET_fieldDetectionUKR`, payload: '' });
    }

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                    {getStepContent(index)}
                    
                    <div className={classes.actionsContainer}>
                      <div>
                        { buttonBlock(activeStep) }
                      </div>
                    </div>

                    </StepContent>
                </Step>
                ))}
            </Stepper>
            
            {activeStep === steps.length && (
              <Paper square elevation={0} className={classes.resetContainer}>
                <div className="my-10 mx-auto w-max">
                  <Typography>Результат збережено до загальної бази даних</Typography>
                </div>
                <div className="w-max mx-auto">
                  <Button variant="contained" color="primary" onClick={handleNewExamination}>
                      Додати нове дослідження
                  </Button>
                </div>
              </Paper>
            )}
        </div>
    )
}

export default Add
