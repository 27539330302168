import React from 'react'

import ClearFilter from './ClearFilter'
import DateFilter from './DateFilter'
import FullTextFilter from './FullTextFilter'

const Filter = () => {

    return (
        <div className="my-4 flex flex-row justify-between items-center gap-4">
            <DateFilter />
            <FullTextFilter />
            <ClearFilter />
        </div>
    )
}

export default Filter
