import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom';

import { store } from '../store';
import Certificate from './certificate'

const Complete = () => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const history = useHistory();

    const handleClick = () => {
        console.log('click');
        dispatch({ type: `SET_HASH`, payload: null });
        dispatch({ type: `SET_fieldFullNameUKR`, payload: '' });
        dispatch({ type: `SET_fieldFullNameENG`, payload: '' });
        dispatch({ type: `SET_fieldNumber`, payload: '' });
        dispatch({ type: `SET_fieldGender`, payload: '' });
        dispatch({ type: `SET_fieldGenderUKR`, payload: '' });
        dispatch({ type: `SET_fieldBirthday`, payload: new Date() });
        dispatch({ type: `SET_fieldPassport`, payload: '' });
        dispatch({ type: `SET_fieldDateRegistration`, payload: new Date() });
        dispatch({ type: `SET_fieldDateSampling`, payload: new Date() });
        dispatch({ type: `SET_fieldDetection`, payload: '' });
        dispatch({ type: `SET_fieldDetectionUKR`, payload: '' });
        history.push("/")
    }

    return (
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col gap-6">
            <div className="w-1/2">

                <div className="text-gray-600 body-font">
                    <div className="bg-gray-100 rounded-lg p-8 flex justify-center flex-col md:ml-auto w-full mt-10 md:mt-0">
                        <h2 className="text-gray-900 text-lg font-medium title-font mb-5">Результат дослідження збережено!</h2>
                        <label className="leading-7 text-sm text-red-600 mb-5">Роздрукуйте або збережіть згенерований файл</label>
                        <button 
                            className="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                            onClick={handleClick}
                        >
                            Додати Нове дослідження
                        </button>
                    </div>
                </div>

            </div>
            
            <div className="w-1/2">
                <Certificate />
            </div>
        </div>
    )
}

export default Complete
