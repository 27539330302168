import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
    user: '',
    pass: '',
    token: {},
    isAuthenticated: false,
    csrfToken: '',
    hash: null,
    fieldFullNameUKR: '',
    fieldFullNameENG: '',
    fieldNumber: '',
    fieldGender: '',
    fieldBirthday: new Date(),
    fieldBirthdayTXT: '',
    fieldGenderUKR: '',
    fieldPassport: '',
    fieldDateRegistration: new Date(),
    fieldDateRegistrationTXT: '',
    fieldDateSampling: new Date(),
    fieldDateSamplingTXT: '',
    fieldDetection: '',
    fieldDetectionUKR: '',
    fieldNid: null,
    fieldPatientNid: null,
    pageResults: true,
    pageAdd: false,
    pageView: false,
    pageEdit: false,
    errorFieldNumber: false,
    errorFieldFullNameUKR: false,
    errorFieldFullNameENG: false,
    errorfieldGenderUKR: false,
    errorfieldGender: false,
    filterDateStart: "0000000000",
    filterDateEnd: "9999999999",
    filterFullText: "",
    selectedDate: new Date(), 
    offset: 24,
    start: 0,
    pages: 1,
    edited: false
}

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer( Reducer, initialState);

    return <Provider value = {{ state, dispatch }}>{ children }</Provider>
}

export { store, StateProvider }