import { gql } from "@apollo/client";

export const GET_RESULTBYID = (id) => gql`
query {
nodeById(id: "${id}") {
    ... on NodeCertificate {
        nid
        queryUid {
        	entities {
          	... on User {
              name
            }
          }
        }
        queryFieldPatient {
          entities {
            ... on NodePatient {
              nid
              fieldFullName
              fieldFullNameUkr
              fieldGender
              fieldGenderUkr
              fieldBirthdayTxt
              fieldBirthday {
                value
                date
              }
              fieldPassport
            }
          }
        }
        fieldNumber
        fieldHash
        fieldDateRegistrationTxt
        fieldDateRegistration {
          value
          date
        }
        fieldDateSamplingTxt
        fieldDateSampling {
          value
          date
        }
        fieldDetection
        fieldDetectionUkr
      }
    }
}
`