import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { store } from '../../../store';
import { GET_RESULTBYID } from '../queryResultById';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@material-ui/core';
import Draggable from 'react-draggable';
import useApi from '../../../api/useApi';

const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
});

const Result = ({nid}) => {
    // const [result, setResult] = useState(null)
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const [ xcsrf, setXcsrf ] = useState(state.csrfToken);
    const { deleteNode, fetchXCSRFToken } = useApi();

    const { loading, error, data, refetch } = useQuery(GET_RESULTBYID(nid))

    useEffect(() => {
      setXcsrf(state.csrfToken)
    }, [state.csrfToken])

    useEffect(() => {
      if(state.edited===true) {
        refetch();
        dispatch({ type: `SET_EDITED`, payload: false });
      }
    }, [state.edited]);

    if (loading) return <div>Loading ...</div>
    if (error) return <div>Error...</div>

    // const getLink = () => {
    //   return `https://pcr.obllik.ck.ua/cert/${data?.fieldHash}`
    // }

    const handleClick = () => {
        dispatch({ type: `SET_pageResults`, payload: false });
        dispatch({ type: `SET_pageAdd`, payload: false });
        dispatch({ type: `SET_pageView`, payload: true });

        dispatch({ type: `SET_HASH`, payload: data.nodeById.fieldHash });
        dispatch({ type: `SET_fieldFullNameUKR`, payload: data.nodeById.queryFieldPatient.entities[0].fieldFullNameUkr });
        dispatch({ type: `SET_fieldFullNameENG`, payload: data.nodeById.queryFieldPatient.entities[0].fieldFullName });
        dispatch({ type: `SET_fieldNumber`, payload: data.nodeById.fieldNumber });
        dispatch({ type: `SET_fieldGender`, payload: data.nodeById.queryFieldPatient.entities[0].fieldGender });
        dispatch({ type: `SET_fieldGenderUKR`, payload: data.nodeById.queryFieldPatient.entities[0].fieldGenderUkr });
        dispatch({ type: `SET_fieldBirthday`, payload: new Date(data.nodeById.queryFieldPatient.entities[0].fieldBirthday.date) });
        dispatch({ type: `SET_fieldBirthdayTXT`, payload: data.nodeById.queryFieldPatient.entities[0].fieldBirthdayTxt });
        dispatch({ type: `SET_fieldPassport`, payload: data.nodeById.queryFieldPatient.entities[0].fieldPassport });
        dispatch({ type: `SET_fieldDateRegistration`, payload: new Date(data.nodeById.fieldDateRegistration.date) });
        dispatch({ type: `SET_fieldDateSampling`, payload: new Date(data.nodeById.fieldDateSampling.date) });
        dispatch({ type: `SET_fieldDateRegistrationTXT`, payload: data.nodeById.fieldDateRegistrationTxt });
        dispatch({ type: `SET_fieldDateSamplingTXT`, payload: data.nodeById.fieldDateSamplingTxt });
        dispatch({ type: `SET_fieldDetection`, payload: data.nodeById.fieldDetection });
        dispatch({ type: `SET_fieldDetectionUKR`, payload: data.nodeById.fieldDetectionUkr });
    }

    const handleClickEdit = () => {
      dispatch({ type: `SET_HASH`, payload: data.nodeById.fieldHash });
      dispatch({ type: `SET_fieldFullNameUKR`, payload: data.nodeById.queryFieldPatient.entities[0].fieldFullNameUkr });
      dispatch({ type: `SET_fieldFullNameENG`, payload: data.nodeById.queryFieldPatient.entities[0].fieldFullName });
      dispatch({ type: `SET_fieldNumber`, payload: data.nodeById.fieldNumber });
      dispatch({ type: `SET_fieldGender`, payload: data.nodeById.queryFieldPatient.entities[0].fieldGender });
      dispatch({ type: `SET_fieldGenderUKR`, payload: data.nodeById.queryFieldPatient.entities[0].fieldGenderUkr });
      dispatch({ type: `SET_fieldBirthday`, payload: new Date(data.nodeById.queryFieldPatient.entities[0].fieldBirthday.date) });
      dispatch({ type: `SET_fieldBirthdayTXT`, payload: data.nodeById.queryFieldPatient.entities[0].fieldBirthdayTxt });
      dispatch({ type: `SET_fieldPassport`, payload: data.nodeById.queryFieldPatient.entities[0].fieldPassport });
      dispatch({ type: `SET_fieldDateRegistration`, payload: new Date(data.nodeById.fieldDateRegistration.date) });
      dispatch({ type: `SET_fieldDateSampling`, payload: new Date(data.nodeById.fieldDateSampling.date) });
      dispatch({ type: `SET_fieldDateRegistrationTXT`, payload: data.nodeById.fieldDateRegistrationTxt });
      dispatch({ type: `SET_fieldDateSamplingTXT`, payload: data.nodeById.fieldDateSamplingTxt });
      dispatch({ type: `SET_fieldDetection`, payload: data.nodeById.fieldDetection });
      dispatch({ type: `SET_fieldDetectionUKR`, payload: data.nodeById.fieldDetectionUkr });
      dispatch({ type: `SET_NID`, payload: data.nodeById.nid });
      dispatch({ type: `SET_PATIENTNID`, payload: data.nodeById.queryFieldPatient.entities[0].nid });

      dispatch({ type: `SET_pageResults`, payload: false });
      dispatch({ type: `SET_pageAdd`, payload: false });
      dispatch({ type: `SET_pageView`, payload: false });
      dispatch({ type: `SET_pageEdit`, payload: true });
    }

    const handleClickDelete = async() => {
      let token = ''
      if (xcsrf==='') {
        token = await fetchXCSRFToken()
        setXcsrf(token)
      }
      await deleteNode(token, data.nodeById.nid);
      await deleteNode(token, data.nodeById.queryFieldPatient.entities[0].nid);
      setOpen(false);
    }

    const handleClickOpen = () => {
      setOpen(true);
    }

    const handleClose = () => {
      setOpen(false);
    }

    function PaperComponent(props) {
      return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
          <Paper {...props} />
        </Draggable>
      );
    }

    return (
      <>
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Оператор: {data.nodeById.queryUid.entities[0].name}
                </Typography>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Дата реєстрації: {data.nodeById.fieldDateRegistrationTxt}
                </Typography>
                <Typography variant="subtitle2" component="div">
                  Номер дослідження: {data.nodeById.fieldNumber}
                </Typography>
                <Typography variant="subtitle2" component="div">
                  Паціент: {data.nodeById.queryFieldPatient.entities[0].fieldFullName}
                </Typography>
                <Typography variant="subtitle2" component="div">
                  Дата народження: {data.nodeById.queryFieldPatient.entities[0].fieldBirthdayTxt}
                </Typography>
            </CardContent>
            <CardActions>
                <Button onClick={handleClick} size="small" variant="contained" color="primary">Перегляд</Button>
                <Button onClick={handleClickEdit} size="small" variant="contained" color="primary">Редагувати</Button>
                <Button onClick={handleClickOpen} size="small" variant="contained" color="primary">Видалити</Button>
            </CardActions>
        </Card>
        <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Видалення
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ви впевнені, що бажаєте видалити запис?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              variant="contained"
              onClick={handleClose}
              color="primary"
              className="focus:outline-none"
            >
              Закрити
            </Button>
            <Button 
              variant="contained" 
              onClick={handleClickDelete} 
              color="secondary"
              className="focus:outline-none"
            >
              Видалити
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
}

export default Result
