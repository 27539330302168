import React, { useContext } from 'react'
import { Button } from '@material-ui/core';

import { store } from '../../../store';
import Certificate from '../../certificate';

const ViewResult = () => {
    const globalState = useContext(store);
    const { dispatch } = globalState;

    const handleClose = () => {
        dispatch({ type: `SET_pageResults`, payload: true });
        dispatch({ type: `SET_pageAdd`, payload: false });
        dispatch({ type: `SET_pageView`, payload: false });
        dispatch({ type: `SET_pageEdit`, payload: false });
    }

    return (
        <div>
            <Certificate />
            <div className="my-10 mx-auto w-max">
                <Button variant="contained" color="primary" onClick={handleClose}>
                    Закрити
                </Button>
            </div>
        </div>
    )
}

export default ViewResult
