import { gql } from '@apollo/client'

export const ADD_PATIENT = (data) => gql`
mutation {
  createPatient(input: ${data}) {
    entity {
      entityId
    }
    errors
    violations {
      message
    }
  }
}
`;

export const GET_USER = (data) => gql`
query {
  userQuery (
    filter: {
      conditions: [{operator: EQUAL, field: "name", value: "${data}"}]
    }
  ) {
    entities {
      ... on User {
        roles {
          targetId
        }
      }
    }
  }
}
`;

export const GET_CERT = (data) => gql`
query {
  nodeQuery (
    filter: {
      conditions: [{operator: EQUAL, field: "field_hash", value: ["${data}"]}]
    }
  ) {
    entities {
      ... on NodeCertificate {
        fieldNumber
        fieldHash
        fieldDateRegistrationTxt
        fieldDateRegistration {
          value
          date
        }
        fieldDateSamplingTxt
        fieldDateSampling {
          value
          date
        }
        queryFieldPatient {
          entities {
            entityId
            ... on NodePatient {
              fieldFullName
              fieldFullNameUkr
              fieldBirthdayTxt
              fieldBirthday {
                value
                date
              }
              fieldGender
              fieldGenderUkr
              fieldPassport
            }
          }
        }
        fieldDetection
        fieldDetectionUkr
      }
    }
  }
}
`;
