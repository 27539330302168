import getAuthClient from "../api/auth";

const auth = getAuthClient();

const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_token':
            return { ...state, token: action.payload}
        case 'SET_isAuthenticated':
            return { ...state, isAuthenticated: action.payload}
        case 'SET_HASH':
            return { ...state, hash: action.payload}
        case 'SET_fieldDateRegistration':
            return { ...state, fieldDateRegistration: action.payload}
        case 'SET_fieldDateSampling':
            return { ...state, fieldDateSampling: action.payload}
        case 'SET_fieldBirthday':
            return { ...state, fieldBirthday: action.payload}
        case 'SET_fieldDateRegistrationTXT':
            return { ...state, fieldDateRegistrationTXT: action.payload}
        case 'SET_fieldDateSamplingTXT':
            return { ...state, fieldDateSamplingTXT: action.payload}
        case 'SET_fieldBirthdayTXT':
            return { ...state, fieldBirthdayTXT: action.payload}
        case 'SET_fieldFullNameUKR':
            return { ...state, fieldFullNameUKR: action.payload}
        case 'SET_fieldFullNameENG':
            return { ...state, fieldFullNameENG: action.payload}
        case 'SET_fieldNumber':
            return { ...state, fieldNumber: action.payload}
        case 'SET_fieldGender':
            return { ...state, fieldGender: action.payload }
        case 'SET_fieldGenderUKR':
            return { ...state, fieldGenderUKR: action.payload }
        case 'SET_fieldPassport':
            return { ...state, fieldPassport: action.payload }
        case 'SET_fieldDetection':
            return { ...state, fieldDetection: action.payload }
        case 'SET_fieldDetectionUKR':
            return { ...state, fieldDetectionUKR: action.payload }
        case 'SET_NID':
            return { ...state, fieldNid: action.payload }
        case 'SET_PATIENTNID':
            return { ...state, fieldPatientNid: action.payload }
        case 'SET_user':
            return { ...state, user: action.payload }
        case 'SET_pass':
            return { ...state, pass: action.payload }
        case 'SET_XCSRF':
            return { ...state, csrfToken: action.payload }
        case 'SET_pageResults':
            return { ...state, pageResults: action.payload }
        case 'SET_pageAdd':
            return { ...state, pageAdd: action.payload }
        case 'SET_pageView':
            return { ...state, pageView: action.payload }
        case 'SET_pageEdit':
            return { ...state, pageEdit: action.payload }
        case 'LOGOUT':
            auth.logout()
            return { ...state, isAuthenticated: action.payload}
        case 'SET_errorFieldNumber':
            return { ...state, errorFieldNumber: action.payload }
        case 'SET_errorFieldFullNameUKR':
            return { ...state, errorFieldFullNameUKR: action.payload }
        case 'SET_errorFieldFullNameENG':
            return { ...state, errorFieldFullNameENG: action.payload }
        case 'SET_errorfieldGenderUKR':
            return { ...state, errorfieldGenderUKR: action.payload }
        case 'SET_errorfieldGender':
            return { ...state, errorfieldGender: action.payload }
        case 'SET_DATASTART':
            return { ...state, filterDateStart: action.payload }
        case 'SET_DATAEND':
            return { ...state, filterDateEnd: action.payload }
        case 'SET_FULLTEXT':
            return { ...state, filterFullText: action.payload }
        case 'SET_OFFSET':
            return { ...state, offset: action.payload }
        case 'SET_START':
            return { ...state, start: action.payload }
        case 'SET_SELECTEDDATE':
            return { ...state, selectedDate: action.payload }
        case 'SET_EDITED':
            return { ...state, edited: action.payload }    
        default:
            return state;
    }
}

export default Reducer