import { useContext } from "react";

import getAuthClient from "../../api/auth";
import { defaultConfig } from "../../api/config";
import { store } from "../../store";

const auth = getAuthClient();

const useApi = () => {

    const globalState = useContext(store);
    const { state, dispatch } = globalState;

    const postPatient = async(token) => {
        const option = {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': token
            }),
            body: JSON.stringify({
                "type": [{"target_id":"patient"}],
                "title": [{"value": `${state.fieldFullNameENG} ${state.fieldBirthday.toLocaleDateString()}`}],
                "field_birthday": [{"value": `${state.fieldBirthday.getFullYear()}-${state.fieldBirthday.getMonth()}-${state.fieldBirthday.getDate()}`}],
                "field_full_name": [{"value": state.fieldFullNameENG}],
                "field_full_name_ukr": [{"value": state.fieldFullNameUKR}],
                "field_gender": [{"value": state.fieldGender}],
                "field_gender_ukr": [{"value": state.fieldGenderUKR}],
                "field_passport": [{"value": state.fieldPassport}]
            })
        }
        let response = await auth.fetchWithAuthentication(`/entity/node`, option)
        let profile = await response.json()

        return Promise.resolve(profile)
    }

    const postCertificate = async(token, patient) => {
        const option = {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': token
            }),
            body: JSON.stringify({
                "type": [{"target_id":"certificate"}],
                "title": [{"value": state.fieldNumber}],
                "field_date_registration": [{"value": state.fieldDateRegistration.toISOString().slice(0,-5)}],
                "field_date_sampling": [{"value": state.fieldDateSampling.toISOString().slice(0,-5)}],
                "field_detection": [{"value": state.fieldDetection}],
                "field_detection_ukr": [{"value": state.fieldDetectionUKR}],
                "field_hash": [{"value": state.hash}],
                "field_number": [{"value": state.fieldNumber}],
                "field_patient": [{"target_id": patient}]
            })
        }
        let response = await auth.fetchWithAuthentication(`/entity/node`, option)
        let profile = await response.json()

        return Promise.resolve(profile)
    }

    const fetchXCSRFToken = async() => {
        let option = {
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        }
        let response = await fetch(`${defaultConfig.base}/session/token`, option)
        let token = await response.text()
        dispatch({type: 'SET_XCSRF', payload: token});
        return Promise.resolve(token);
    }

    return {
        postPatient,
        postCertificate,
        fetchXCSRFToken
    }
}

export default useApi
