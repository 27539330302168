import React, { useContext, useEffect } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PostAddIcon from '@material-ui/icons/PostAdd';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import { store } from '../../store'

const useListitems = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const handleListItemClick = (e, key) => {
    switch (key) {
      case 0:
        dispatch({ type: `SET_pageAdd`, payload: true });
        dispatch({ type: `SET_pageResults`, payload: false });
        dispatch({ type: `SET_pageView`, payload: false });
        dispatch({ type: `SET_pageEdit`, payload: false });
        break;
      case 1:
        dispatch({ type: `SET_pageResults`, payload: true });
        dispatch({ type: `SET_pageAdd`, payload: false });
        dispatch({ type: `SET_pageView`, payload: false });
        dispatch({ type: `SET_pageEdit`, payload: false });
        break;
      case 2:
        dispatch({ type: `LOGOUT`, payload: false });
        break;
      default:
        break;
    }
  }

  const mainListItems = (
    <div>
      <ListItem 
        button 
        onClick={(e) => handleListItemClick(e, 0)}
      >
        <ListItemIcon>
          <PostAddIcon />
        </ListItemIcon>
        <ListItemText primary="Додати Тест" />
      </ListItem>
      <ListItem 
        button
        onClick={(e) => handleListItemClick(e, 1)}
      >
        <ListItemIcon>
          <LibraryBooksIcon />
        </ListItemIcon>
        <ListItemText primary="Результати" />
      </ListItem>
    </div>
  );

  const secondaryListItems = (
    <div>
      <ListItem
        button
        onClick={(e) => handleListItemClick(e, 2)}
      >
        <ListItemIcon>
          <PowerSettingsNewIcon />
        </ListItemIcon>
        <ListItemText primary="Вихід" />
      </ListItem>
    </div>
  );

  return {
    mainListItems,
    secondaryListItems
  }

}

export default useListitems