import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context';
import getAuthClient from './auth';

const auth = getAuthClient();

const httpLink = createHttpLink({
    uri: 'https://api.obllik.ck.ua/graphql'
});

const authLink = setContext(async(_, { headers }) => {
    // get the authentication token from local storage if it exists
    // let token = localStorage.getItem('obllik.state.token')
    
    let token = await auth.token()
    
    if (!token) {
        await auth.login();
        token = JSON.parse(localStorage.getItem('obllik.state.token'))
    }

    return {
        headers: {
            ...headers,
            Authorization:`Bearer ${token.access_token}`
        }
    }
});

const Client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
})

export default Client