import { useQuery } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from "react-router-dom"

// import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';

import { store } from '../store'
import Certificate from './certificate'
import { GET_CERT } from './GET_CERT'
// import getAuthClient from '../api/auth';

const Cert = () => {
    const { id } = useParams();
    const [visible, setVisible] = useState(false)
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const { loading, error, data } = useQuery(GET_CERT(id))
    
    useEffect(async() => {      
        if (id !=null) {
            dispatch({ type: 'SET_HASH', payload: id })
        }
    }, [])

    // useEffect(() => {
    //     if (state.hash != null) {
    //         console.log(state);
    //     }
    // }, [state])

    // useEffect(() => {
    //     const result = data?.nodeQuery.entities[0];
    //     const patient = result?.queryFieldPatient.entities[0];
    //     dispatch({ type: `SET_fieldNumber`, payload: result?.fieldNumber });
    //     console.log('object');
    // }, [data])


    useEffect(() => {
        
        if (data) {
            const result = data.nodeQuery.entities[0];
            const patient = result.queryFieldPatient.entities[0];
            console.log('result: ', result);
            let dateRegistration = new Date(result.fieldDateRegistration.value)
            dateRegistration.setHours(dateRegistration.getHours()+Math.abs((new Date().getTimezoneOffset())/60))
            // console.log(Math.abs((new Date().getTimezoneOffset())/60));
            let dateSampling = new Date(result.fieldDateSampling.value)
            dateSampling.setHours(dateSampling.getHours()+Math.abs((new Date().getTimezoneOffset())/60))

            dispatch({ type: `SET_fieldDateRegistration`, payload: dateRegistration });
            dispatch({ type: `SET_fieldDateSampling`, payload: dateSampling });
            dispatch({ type: `SET_fieldDateRegistrationTXT`, payload: result.fieldDateRegistrationTxt });
            dispatch({ type: `SET_fieldDateSamplingTXT`, payload: result.fieldDateSamplingTxt });
            dispatch({ type: `SET_fieldDetection`, payload: result.fieldDetection });
            dispatch({ type: `SET_fieldDetectionUKR`, payload: result.fieldDetectionUkr });
            dispatch({ type: `SET_fieldNumber`, payload: result.fieldNumber });
            dispatch({ type: `SET_fieldFullNameENG`, payload: patient.fieldFullName });
            dispatch({ type: `SET_fieldFullNameUKR`, payload: patient.fieldFullNameUkr });
            dispatch({ type: `SET_fieldBirthday`, payload: new Date(Date.parse(patient.fieldBirthday.value)) });
            dispatch({ type: `SET_fieldBirthdayTXT`, payload: patient.fieldBirthdayTxt });
            dispatch({ type: `SET_fieldGender`, payload: patient.fieldGender });
            dispatch({ type: `SET_fieldGenderUKR`, payload: patient.fieldGenderUkr });
            dispatch({ type: `SET_fieldPassport`, payload: patient.fieldPassport });
        }
    }, [data])

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error...</p>

    // const timeout = () => {
    //     setTimeout(() => setVisible(true), 1000)
    // }

    // const VisibleBtn = () => {
    //     if (visible) {
    //         return (
    //             <div className="w-full flex justify-center">
    //                 <Certificate />
    //             </div>
    //         )    
    //     } else {
    //         return null
    //     }
    // }

    return (
        <div className="container mx-auto px-5 flex-col gap-6">
        <div className="flex justify-center flex-col gap-6">
            <div className="mt-10 w-full flex justify-center flex-col">
            <Typography className="py-10 text-center" variant="h5" component="h1">
                КНП Черкаська обласна лікарня Черкаської обласної ради
            </Typography>
            <Typography> Дослідження №: {state.fieldNumber} </Typography>
            <Typography> Дата реєстрації: {state.fieldDateRegistrationTXT} </Typography>
            <Typography> Дата виконання: { state.fieldDateSamplingTXT}</Typography>
            <Typography> Пацієнт: {state.fieldFullNameENG} </Typography>
            <Typography> Дата народження: {state.fieldBirthdayTXT} </Typography>
            <Typography> Паспорт: {state.fieldPassport} </Typography>
            <Typography> Результат: {state.fieldDetection} </Typography>
            </div>
            <Certificate />           
        </div>
        </div>
    )
}

export default Cert
