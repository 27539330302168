import { defaultConfig } from "./config";

const refreshPromises = []

const getAuthClient = (config = {}) => {
    config = {...defaultConfig, ...config}

    async function login() {
        let formData = new FormData()
        formData.append('grant_type', 'password')
        formData.append('client_id', config.client_id)
        formData.append('client_secret', config.client_secret)
        formData.append('scope', config.scope)
        formData.append('username', config.rest_id)
        formData.append('password', config.rest_sp)
        try {
            const response = await fetch(`${config.base}/oauth/token`, {
                method: 'post',
                headers: new Headers({
                    'Accept': 'application/json'
                }),
                body: formData
            })
            const data = await response.json()
            if (data.error) {
                console.log('Error retriving token', data)
                return false
            }
            return saveToken(data)
        } catch (error) {
            return console.log('We got an error: ', error)
        }
    }

    async function userLogin( username, password) {
        let formData = new FormData()
        formData.append('grant_type', 'password')
        formData.append('client_id', config.client_id)
        formData.append('client_secret', config.client_secret)
        formData.append('scope', config.scope)
        formData.append('username', username)
        formData.append('password', password)
        try {
            const response = await fetch(`${config.base}/oauth/token`, {
                method: 'post',
                headers: new Headers({
                    'Accept': 'application/json'
                }),
                body: formData
            })
            const data = await response.json()
            if (data.error) {
                console.log('Error retriving token', data)
                return false
            }
            return saveToken(data)
        } catch (error) {
            return console.log('We got an error: ', error)
        }
    }

    function logout() {
        localStorage.removeItem('obllik.state.token')
        return Promise.resolve(true)
    }

    async function fetchWithAuthentication(url, options) {
        if (!options.headers.get('Authorization')) {
            const oauth_token = await token()
            if (oauth_token) {
                options.headers.append('Authorization', `Bearer ${oauth_token.access_token}`)
            }
            return fetch(`${config.base}${url}`, options);
        }
    }

    async function token() {
        const token = localStorage.getItem('obllik.state.token') !== null
            ? JSON.parse(localStorage.getItem('obllik.state.token'))
            : false

        if (!token) {
            await login()
            // Promise.reject()
        }

        const { expires_at, refresh_token } = token
        if (expires_at - config.expire_margin < Date.now()/1000) {
            return refreshToken(refresh_token)
        }
        return Promise.resolve(token)
    }

    function refreshToken(refresh_token) {
        console.log("getting refresh token")
        
        if (refreshPromises[refresh_token]) {
            return refreshPromises[refresh_token]
        }

        let formData = new FormData()
        formData.append('grant_type', 'refresh_token')
        formData.append('client_id', config.client_id)
        formData.append('client_secret', config.client_secret)
        formData.append('refresh_token', refresh_token)

        return (refreshPromises[refresh_token] = fetch(`${config.base}/oauth/token`, {
            method: 'post',
            headers: new Headers({
                'Accept': 'application/json'
            }),
            body: formData
        })
        .then(function(response) {
            return response.json()
        })
        .then((data) => {
            delete refreshPromises[refresh_token]
    
            if (data.error) {
                console.log('Error refreshing token: ', data)
                return false
            }
            return saveToken(data)
        })
        .catch(err => {
            delete refreshPromises[refresh_token]
            console.log('We got an error: ', err)
            return Promise.reject(err)
        }))
    }

    function saveToken(data) {
        let token = Object.assign({}, data)
        token.date = Math.floor(Date.now() / 1000)
        token.expires_at = token.date + token.expires_in
        localStorage.setItem('obllik.state.token', JSON.stringify(token))
        //localStorage.setItem('state.products', JSON.stringify(productsValue))
        return token
    }

    async function isLoggedIn() {
        const oauth_token = await token()
        if (oauth_token) {
            return Promise.resolve(true)
        }
        return Promise.reject(false)
    }

    function debug() {
        const headers = new Headers({
          Accept: 'application/vnd.api+json',
        })
    
        fetchWithAuthentication(`${config.base}/oauth/debug?_format=json`, {headers})
          .then((response) => response.json())
          .then((data) => {
            console.log('debug', data)
          })
    }
    
    return {debug, login, logout, userLogin, isLoggedIn, fetchWithAuthentication, token, refreshToken}
}

export default getAuthClient