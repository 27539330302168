import { Button } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'

import { store } from '../../store';
import FormAdd from '../certificates/FormAdd'
import useApi from '../../api/useApi';

const Edit = () => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const [ xcsrf, setXcsrf ] = useState(state.csrfToken);
    const { patchPatient, patchCertificate, fetchXCSRFToken } = useApi();

    useEffect(() => {
        setXcsrf(state.csrfToken)
    }, [state.csrfToken])

    const handleClose = () => {
        dispatch({ type: `SET_pageResults`, payload: true });
        dispatch({ type: `SET_pageAdd`, payload: false });
        dispatch({ type: `SET_pageView`, payload: false });
        dispatch({ type: `SET_pageEdit`, payload: false });
    }

    const handleClickSave = async() => {
        let token = ''
        if (xcsrf==='') {
            token = await fetchXCSRFToken()
            setXcsrf(token)
        }
        await patchPatient(token, state.fieldPatientNid);
        await patchCertificate(token, state.fieldNid);
        dispatch({ type: `SET_EDITED`, payload: true });

        dispatch({ type: `SET_pageResults`, payload: true });
        dispatch({ type: `SET_pageAdd`, payload: false });
        dispatch({ type: `SET_pageView`, payload: false });
        dispatch({ type: `SET_pageEdit`, payload: false });
    }

    return (
        <div className="flex flex-col gap-4">
            <FormAdd />
            <div className="gap-4 flex flex-row justify-end">
                <Button
                    autoFocus
                    variant="contained"
                    onClick={handleClose}
                    color="primary"
                    className="focus:outline-none"
                >
                Закрити
                </Button>
                <Button 
                    variant="contained" 
                    onClick={handleClickSave} 
                    color="primary"
                    className="focus:outline-none"
                >
                Зберегти
                </Button>
            </div>
        </div>
    )
}

export default Edit
