import React, { useContext, useEffect } from 'react';

import { store } from '../../store';
import getAuthClient from '../../api/auth';
import { useHistory } from 'react-router-dom';

const auth = getAuthClient();

const LoginForm = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const history = useHistory();

  useEffect(() => {
    
    if (state.isAuthenticated) {
      console.log(state.isAuthenticated);
      history.push("/")
    }
    
  }, [state.isAuthenticated])

  const handleChange = (e) => {
    dispatch({ type: `SET_${e.target.name}`, payload: e.target.value });
  }

  const handleSubmit = async() => {
    let data = await auth.userLogin( state.user, state.pass )
    
    if (data===false) {
      console.log('failed');
    } else {
      dispatch({ type: `SET_isAuthenticated`, payload: true });
    }
    return
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Зайти на Ваш аккаунт</h2>
          
        </div>
        <div className="mt-8 space-y-6">
          
          <div className="rounded-md shadow-sm -space-y-px">
            
              
              <input
                name="user"
                type="user"
                onChange={handleChange}
                autoComplete="user"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Ім'я користувача"
              />
            
            
              <input
                name="pass"
                type="password"
                onChange={handleChange}
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Пароль"
              />
            
          </div>

          <div>
            <button
              
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleSubmit}
            >
              Вхід
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginForm
