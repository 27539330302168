import React, { useContext, useEffect, useState } from 'react'
import InputMask from 'react-input-mask';
import { store } from '../../store';
import useApi from './useApi';
import { useHistory } from 'react-router-dom';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import hash from 'object-hash'

const PCRForm = () => {    
    const curdate = new Date();
    let history = useHistory()
    const addZero = (n) => {
        return n<10? `0${n}`:`${n}`;
    }

    const [registration, setRegistration] = useState(`${curdate.getFullYear()}-${addZero(curdate.getMonth()+1)}-${addZero(curdate.getDate())} ${addZero(curdate.getHours())}:${addZero(curdate.getMinutes())}`);
    
    const [sampling, setSampling] = useState(`${curdate.getFullYear()}-${addZero(curdate.getMonth()+1)}-${addZero(curdate.getDate())} ${addZero(curdate.getHours())}:${addZero(curdate.getMinutes())}`);
    
    const [birthday, setBirthday] = useState(`${curdate.getFullYear()}-${addZero(curdate.getMonth()+1)}-${addZero(curdate.getDate())}`);

    // const [addPatient] = useMutation(ADD_PATIENT({"title": "Jason Tatob, 1977"}))
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const [ xcsrf, setXcsrf ] = useState(state.csrfToken);
    const { postPatient, postCertificate, fetchXCSRFToken } = useApi();

    useEffect(() => {
        const data = hash(state)
        dispatch({ type: 'SET_HASH', payload: data })
    }, [])

    useEffect(() => {
        dispatch({ type: `SET_fieldDateRegistration`, payload: new Date(registration) });
    }, [registration])

    useEffect(() => {
        dispatch({ type: `SET_fieldBirthday`, payload: new Date(birthday) });
    }, [birthday])

    useEffect(() => {
        dispatch({ type: `SET_fieldDateSampling`, payload: new Date(sampling) });
    }, [sampling])

    useEffect(() => {
        console.log(state);
    }, [state])

    useEffect(() => {
        setXcsrf(state.csrfToken)
    }, [state.csrfToken])

    const handleChange = e => {
        dispatch({ type: `SET_${e.target.name}`, payload: e.target.value });
    }

    
    const handleClick = async() => {

        let token = ''
        if (xcsrf==='') {
            token = await fetchXCSRFToken()

            setXcsrf(token)
        }
        let profile = await postPatient(token);
        console.log(profile.nid[0].value);
        await postCertificate(token, profile.nid[0].value)
        history.push(`/complete/${state.hash}`)
    }

    const handleFullNameUKR = (e) => {
        let cyrillicToTranslit = new CyrillicToTranslit();
        dispatch({ type: `SET_fieldFullNameUKR`, payload: e.target.value });
        dispatch({ type: `SET_fieldFullNameENG`, payload: cyrillicToTranslit.transform(`${e.target.value}`) });
    }

    const handleFullNameENG = (e) => {
        let cyrillicToTranslit = new CyrillicToTranslit();
        dispatch({ type: `SET_fieldFullNameENG`, payload: e.target.value });
        dispatch({ type: `SET_fieldFullNameUKR`, payload: cyrillicToTranslit.reverse(`${e.target.value}`) });
    }

    const handleGenderChange = (e) => {
        dispatch({ type: `SET_${e.target.name}`, payload: e.target.value });
        if (e.target.value === 'жін') {
            dispatch({ type: `SET_fieldGender`, payload: 'female' });
        }

        if (e.target.value === 'чол') {
            dispatch({ type: `SET_fieldGender`, payload: 'male' });
        }
    }

    const handleGenderChangeENG = (e) => {
        dispatch({ type: `SET_${e.target.name}`, payload: e.target.value });
        if (e.target.value === 'female') {
            dispatch({ type: `SET_fieldGenderUKR`, payload: 'жін' });
        }

        if (e.target.value === 'male') {
            dispatch({ type: `SET_fieldGenderUKR`, payload: 'чол' });
        }
    }

    const handleDetection = e => {
        dispatch({ type: `SET_${e.target.name}`, payload: e.target.value });

        switch (e.target.value) {
            case 'негативний':
                dispatch({ type: `SET_fieldDetection`, payload: 'negative' });
                break;
            case 'позитивний':
                dispatch({ type: `SET_fieldDetection`, payload: 'positive' });
                break;
            case 'negative':
                dispatch({ type: `SET_fieldDetectionUKR`, payload: 'негативний' });
                break;
            case 'positive':
                dispatch({ type: `SET_fieldDetectionUKR`, payload: 'позитивний' });
                break;
            default:
                break;
        }
    }

    return (
        <section className="text-gray-600 body-font">
            <div className="bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
                <h2 className="text-gray-900 text-lg font-medium title-font mb-5">EXAMINATION RESULT</h2>
                <div className="relative mb-4">
                    <label className="leading-7 text-sm text-gray-600">Examination result number</label>
                    <input 
                        type="text"
                        name="fieldNumber"
                        onChange={handleChange}
                        className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                </div>

                <div className="relative mb-4">
                    <label className="leading-7 text-sm text-gray-600">Registration Date/Time</label>
                    
                    <InputMask
                        mask="9999-99-99 99:99"
                        placeholder="YYYY-MM-DD HH:MM"
                        value={registration}
                        onChange={(e) => {setRegistration(e.target.value)}}
                        className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                        
                </div>

                <div className="flex flex-row justify-between gap-4">
                    <div className="relative mb-4 w-1/2">
                        <label className="leading-7 text-sm text-gray-600">Паціент</label>
                        <input 
                            type="text"
                            name="fieldFullNameUKR"
                            value={state.fieldFullNameUKR}
                            onChange={handleFullNameUKR}
                            className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                    </div>
                    <div className="relative mb-4 w-1/2">
                        <label className="leading-7 text-sm text-gray-600">Patient</label>
                        <input 
                            type="text"
                            name="fieldFullNameENG"
                            value={state.fieldFullNameENG}
                            onChange={handleFullNameENG}
                            className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                    </div>
                </div>


                <div className="relative mb-4">
                    <label className="leading-7 text-sm text-gray-600">Data of birth:</label>
                    
                    <InputMask
                        mask="9999-99-99"
                        placeholder="YYYY-MM-DD"
                        value={birthday}
                        onChange={(e) => {setBirthday(e.target.value)}}
                        className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                        
                </div>

                <div className="flex flex-row justify-between gap-4">
                    <div className="relative mb-4 w-1/2">
                        <label className="leading-7 text-sm text-gray-600">Стать</label>
                        <select 
                            className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            name='fieldGenderUKR'
                            value={state.fieldGenderUKR}
                            onChange={handleGenderChange}
                        >
                            <option value="">Оберіть ...</option>
                            <option value="жін">жін</option>
                            <option value="чол">чол</option>
                        </select>
                    </div>

                    <div className="relative mb-4 w-1/2">
                        <label className="leading-7 text-sm text-gray-600">Gender</label>
                        <select 
                            className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            name='fieldGender'
                            value={state.fieldGender}
                            onChange={handleGenderChangeENG}
                        >
                            <option value="">Select ...</option>
                            <option value="female">FEMALE</option>
                            <option value="male">MALE</option>
                        </select>
                    </div>
                    
                </div>


                <div className="relative mb-4">
                    <label className="leading-7 text-sm text-gray-600">Passport</label>
                    <input 
                        type="text"
                        name="fieldPassport"
                        onChange={handleChange}
                        className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                </div>


                <div className="relative mb-4">
                    <label className="leading-7 text-sm text-gray-600">Date of sampling/Time of sampling:</label>
                    
                    <InputMask
                        mask="9999-99-99 99:99"
                        placeholder="YYYY-MM-DD HH:MM"
                        value={sampling}
                        onChange={(e) => {setSampling(e.target.value)}}
                        className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                        
                </div>

                <div className="flex flex-row justify-between gap-4">
                    <div className="relative mb-4 w-1/2">
                        <label className="leading-7 text-sm text-gray-600">Визначення</label>
                        <select 
                            name="fieldDetectionUKR"
                            onChange={handleDetection}
                            value={state.fieldDetectionUKR}
                            className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        >
                            <option value="">Оберіть результат ...</option>
                            <option value="негативний">негативний</option>
                            <option value="позитивний">позитивний</option>
                        </select>
                    </div>
                    <div className="relative mb-4 w-1/2">
                        <label className="leading-7 text-sm text-gray-600">Detection</label>
                        <select 
                            name="fieldDetection"
                            onChange={handleDetection}
                            value={state.fieldDetection}
                            className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        >
                            <option value="">Select result ...</option>
                            <option value="negative">negative</option>
                            <option value="positive">positive</option>
                        </select>
                    </div>
                </div>

                <button 
                    className="text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                    onClick={handleClick}
                >
                    Зберегти
                </button>
            </div>
        </section>
    )
}

export default PCRForm
