import { gql } from "@apollo/client"

const GET_SEARCH = (datastart, dataend, filterFullText) => gql`
query {
    searchAPISearch (
        index_id: "date_search",
        sort: {field: "created", value: "desc"},
        fulltext: {keys: "${filterFullText}", fields: ["field_full_name", "field_full_name_ukr", "field_number", "field_passport"]}
        condition_group: {
        conjunction: AND,
        groups: [
            {
            conjunction: AND,
            conditions: [
                {operator: ">", name: "created", value: "${datastart}"},
                {operator: "<", name: "created", value: "${dataend}"}
            ]
            }
        ]
        },
        range: {limit:1000, offset: 0}
    ) {
        documents {
            ... on DateSearchDoc {
                created
                field_date_registration_txt
                nid
            }
        }
    }
}
`

export default GET_SEARCH