import { Button } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import React, { useContext } from 'react'

import { store } from '../../store';

const ClearFilter = () => {
    
    const globalState = useContext(store)
    const { dispatch } = globalState

    const handleClick = () => {
        dispatch({ type: `SET_DATASTART`, payload: "0000000000" });
        dispatch({ type: `SET_DATAEND`, payload: '9999999999' });
        dispatch({ type: `SET_SELECTEDDATE`, payload: new Date() });
        dispatch({ type: `SET_FULLTEXT`, payload: "" });
    }

    return (
        <Button
            variant="contained"
            color="default"
            startIcon={<HighlightOffIcon />}
            onClick={handleClick}
            className="focus:outline-none w-1/4"
        >
            Очистити
        </Button>
    )
}

export default ClearFilter
