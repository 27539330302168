import './App.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import locale from "date-fns/locale/uk";

import {
  BrowserRouter as Router,
} from "react-router-dom";

import routers from './Routers';

const App = () => {

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <Router>
        { routers }
      </Router>
    </MuiPickersUtilsProvider>
  );
}

export default App;
