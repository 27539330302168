import { useQuery } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'

import Box from '@material-ui/core/Box'

import Result from './result/Result'
import Filter from '../filter'
import { store } from '../../store'
import GET_SEARCH from './querySearch'

const Results = () => {
    const globalState = useContext(store)
    const { state } = globalState

    const [certificates, setCertificates] = useState(null)
    const { loading, error, data } = useQuery(GET_SEARCH(state.filterDateStart, state.filterDateEnd, state.filterFullText), { pollInterval: 500 })

    useEffect(() => {
        if (data) {
            setCertificates(data.searchAPISearch.documents)
        }
    }, [data])

    if (loading) return <div>Loading ...</div>
    if (error) return <div>Error...</div>

    return (
        <div className="flex flex-col gap-4">
            <Filter />
            <Box display="flex" flexWrap="wrap" className="gap-4 justify-start">
                { certificates?.map((item, key)=>{
                    return ( <Result key={key} nid={item.nid} /> )
                }) }
            </Box>
        </div>
    )
}

export default Results
