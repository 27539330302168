import React from 'react'

import PCRForm from './pcrform'
import Certificate from './certificate'

const Home = () => {
    return (
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col gap-6">
            <div className="w-1/2">
                <PCRForm />
            </div>
            <div className="w-1/2">
                <Certificate />
            </div>
        </div>
    )
}

export default Home
