import React from 'react';
import ReactDOM from 'react-dom';
import {ApolloProvider} from '@apollo/client';

import './index.css';
import App from './App';
import Client from './api/client'
import { StateProvider } from './store';

ReactDOM.render(

    <ApolloProvider client={Client}>
      <StateProvider>
        <App />
      </StateProvider>
    </ApolloProvider>,

  document.getElementById('root')
);

