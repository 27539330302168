import React, { useContext, useEffect, useState } from 'react'
// import { useHistory } from 'react-router-dom'
import InputMask from 'react-input-mask'
import CyrillicToTranslit from 'cyrillic-to-translit-js'
import hash from 'object-hash'

import { TextField } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import { store } from '../../store';

const FormAdd = () => {
    const [errorFieldNumber, setErrorFieldNumber] = useState(false)
    const [errorFieldFullNameUKR, setErrorFieldFullNameUKR] = useState(false)
    const [errorFieldFullNameENG, setErrorFieldFullNameENG] = useState(false)
    // let history = useHistory()
    const addZero = (n) => {
        return n<10? `0${n}`:`${n}`;
    }
    const globalState = useContext(store);
    const { state, dispatch } = globalState;

    const [registration, setRegistration] = useState(`${addZero(state.fieldDateRegistration.getDate())}.${addZero(state.fieldDateRegistration.getMonth()+1)}.${state.fieldDateRegistration.getFullYear()}/${addZero(state.fieldDateRegistration.getHours())}:${addZero(state.fieldDateRegistration.getMinutes())}`);
    
    const [sampling, setSampling] = useState(`${addZero(state.fieldDateRegistration.getDate())}.${addZero(state.fieldDateRegistration.getMonth()+1)}.${state.fieldDateRegistration.getFullYear()}/${addZero(state.fieldDateRegistration.getHours())}:${addZero(state.fieldDateRegistration.getMinutes())}`);
    
    const [birthday, setBirthday] = useState(`${addZero(state.fieldDateRegistration.getDate())}.${addZero(state.fieldDateRegistration.getMonth()+1)}.${state.fieldDateRegistration.getFullYear()}`);

    // const [addPatient] = useMutation(ADD_PATIENT({"title": "Jason Tatob, 1977"}))
    const [ xcsrf, setXcsrf ] = useState(state.csrfToken);
    // const { postPatient, postCertificate, fetchXCSRFToken } = useApi();

    useEffect(() => {
        dispatch({ type: `SET_fieldBirthday`, payload: new Date() });
        dispatch({ type: `SET_fieldDateRegistration`, payload: new Date() });
        dispatch({ type: `SET_fieldDateSampling`, payload: new Date() });
        if(state.fieldDateRegistration != '') {
            const data = hash(state.fieldDateRegistration)
            dispatch({ type: 'SET_HASH', payload: data })
        }
    }, [])

    useEffect(() => {
        // dispatch({ type: `SET_fieldDateRegistration`, payload: new Date(registration) });
        dispatch({ type: `SET_fieldDateRegistrationTXT`, payload: registration });
    }, [registration])

    useEffect(() => {
        // let dataBirthday = new Date(birthday)
        // if (!isNaN(dataBirthday)) {
        //     dataBirthday.setMonth(dataBirthday.getMonth()+1)
        //     console.log(dataBirthday);
        //     dispatch({ type: `SET_fieldBirthday`, payload: dataBirthday }); 
        // }
        // dispatch({ type: `SET_fieldBirthday`, payload: new Date(birthday) });
        dispatch({ type: `SET_fieldBirthdayTXT`, payload: birthday });
    }, [birthday])

    useEffect(() => {
        // dispatch({ type: `SET_fieldDateSampling`, payload: new Date(sampling) });
        dispatch({ type: `SET_fieldDateSamplingTXT`, payload: sampling });
    }, [sampling])

    useEffect(() => {
        setXcsrf(state.csrfToken)
    }, [state.csrfToken])

    useEffect(() => {
        setErrorFieldFullNameUKR(state.errorFieldFullNameUKR)
    }, [state.errorFieldFullNameUKR])

    useEffect(() => {
        setErrorFieldFullNameENG(state.errorFieldFullNameENG)
    }, [state.errorFieldFullNameENG])

    // change functions

    const handleChange = e => {
        dispatch({ type: `SET_${e.target.name}`, payload: e.target.value });
    }

    const handleFullNameUKR = (e) => {
        let cyrillicToTranslit = new CyrillicToTranslit();
        dispatch({ type: `SET_fieldFullNameUKR`, payload: e.target.value });
        dispatch({ type: `SET_fieldFullNameENG`, payload: cyrillicToTranslit.transform(`${e.target.value}`) });
    }

    const handleFullNameENG = (e) => {
        // let cyrillicToTranslit = new CyrillicToTranslit();
        dispatch({ type: `SET_fieldFullNameENG`, payload: e.target.value });
        // dispatch({ type: `SET_fieldFullNameUKR`, payload: cyrillicToTranslit.reverse(`${e.target.value}`) });
    }

    const handleGenderChange = (e) => {
        dispatch({ type: `SET_${e.target.name}`, payload: e.target.value });
        if (e.target.value === 'жін') {
            dispatch({ type: `SET_fieldGender`, payload: 'female' });
        }

        if (e.target.value === 'чол') {
            dispatch({ type: `SET_fieldGender`, payload: 'male' });
        }
    }

    const handleGenderChangeENG = (e) => {
        dispatch({ type: `SET_${e.target.name}`, payload: e.target.value });
        if (e.target.value === 'female') {
            dispatch({ type: `SET_fieldGenderUKR`, payload: 'жін' });
        }

        if (e.target.value === 'male') {
            dispatch({ type: `SET_fieldGenderUKR`, payload: 'чол' });
        }
    }

    const handleDetection = e => {
        dispatch({ type: `SET_${e.target.name}`, payload: e.target.value });

        switch (e.target.value) {
            case 'негативний':
                dispatch({ type: `SET_fieldDetection`, payload: 'negative' });
                break;
            case 'позитивний':
                dispatch({ type: `SET_fieldDetection`, payload: 'positive' });
                break;
            case 'negative':
                dispatch({ type: `SET_fieldDetectionUKR`, payload: 'негативний' });
                break;
            case 'positive':
                dispatch({ type: `SET_fieldDetectionUKR`, payload: 'позитивний' });
                break;
            default:
                break;
        }
    }

    const formatChars = {
        n: '[0-9]',
        m: '[0-5]',
        e: '[0-3]',
        d: '[0-9]',
        z: '[1-2]',
        h: '[0-2]',
        y: '[0-1]',
      }

    return (
        <section className="text-gray-600 body-font">
            <div className="bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
                <h2 className="text-gray-900 text-lg font-medium title-font mb-5">РЕЗУЛЬТАТ ДОСЛІДЖЕННЯ / EXAMINATION RESULT</h2>
                <div className="relative mb-4">
                    <TextField 
                        className="w-full"
                        name="fieldNumber"
                        value = {state.fieldNumber}
                        onChange={handleChange}
                        label="Номер дослідження / Examination result number"
                        variant="outlined" 
                        error = {state.errorFieldNumber}
                        required
                    />
                </div>

                <div className="relative mb-4">
                    <InputMask 
                        mask="en.yn.znnn/hn:mn"
                        placeholder="DD.MM.YYYY/HH:MM"
                        formatChars={formatChars}
                        value={registration}
                        onChange={(e) => {setRegistration(e.target.value)}}
                    >
                        {(inputProps) => <TextField 
                            {...inputProps}
                            className="w-full"
                            label="Дата/Час реєстрації / Registration Date/Time"
                            variant="outlined" 
                        />}
                    </InputMask>
                </div>

                <div className="flex flex-row justify-between gap-4">
                    <div className="relative mb-4 w-1/2">
                        <TextField 
                            name="fieldFullNameUKR"
                            value={state.fieldFullNameUKR}
                            onChange={handleFullNameUKR}
                            label="Паціент"
                            className="w-full"
                            variant="outlined" 
                            error = {errorFieldFullNameUKR}
                            required
                        />
                    </div>
                    <div className="relative mb-4 w-1/2">
                        <TextField 
                            name="fieldFullNameENG"
                            value={state.fieldFullNameENG}
                            onChange={handleFullNameENG}
                            label="Patient"
                            className="w-full"
                            variant="outlined" 
                            error = {errorFieldFullNameENG}
                            required
                        />
                    </div>
                </div>


                <div className="relative mb-4">
                    
                    <InputMask
                        mask="en.yn.znnn"
                        placeholder="DD.MM.YYYY"
                        formatChars={formatChars}
                        value={birthday}
                        onChange={(e) => {setBirthday(e.target.value)}}
                    >
                        {(inputProps) => <TextField 
                            {...inputProps}
                            className="w-full"
                            label="Дата народження / Data of birth"
                            variant="outlined" 
                        />}
                    </InputMask>  
                </div>

                <div className="flex flex-row justify-between gap-4">
                    <div className="relative mb-4 w-1/2">
                        <FormControl 
                            variant="outlined"
                            className="w-full"
                            error = {state.errorfieldGenderUKR}
                        >
                            <InputLabel id="gender-label" className="bg-white">Стать</InputLabel>
                            <Select
                                labelId="gender-label"
                                name='fieldGenderUKR'
                                value={state.fieldGenderUKR}
                                onChange={handleGenderChange}
                            >
                                <MenuItem value="жін">жін</MenuItem>
                                <MenuItem value="чол">чол</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className="relative mb-4 w-1/2">
                        <FormControl 
                            variant="outlined"
                            className="w-full"
                            error = {state.errorfieldGender}
                        >
                            <InputLabel id="gender-label-eng" className="bg-white">Gender</InputLabel>
                            <Select
                                labelId="gender-label-eng"
                                name='fieldGender'
                                value={state.fieldGender}
                                onChange={handleGenderChangeENG}
                            >
                                <MenuItem value="female">female</MenuItem>
                                <MenuItem value="male">male</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    
                </div>


                <div className="relative mb-4">
                    
                    <TextField 
                        name="fieldPassport"
                        value={state.fieldPassport}
                        onChange={handleChange}
                        label="Паспорт / Passport"
                        className="w-full"
                        variant="outlined" 
                    />
                </div>


                <div className="relative mb-4">
                    
                    <InputMask 
                        mask="en.yn.znnn/hn:mn"
                        placeholder="DD.MM.YYYY/HH:MM"
                        formatChars={formatChars}
                        value={sampling}
                        onChange={(e) => {setSampling(e.target.value)}}
                    >
                        {(inputProps) => <TextField 
                            {...inputProps}
                            className="w-full"
                            label="Дата/Час виконання / Date/Time of sampling"
                            variant="outlined" 
                        />}
                    </InputMask>
                </div>

                <div className="flex flex-row justify-between gap-4">
                    <div className="relative mb-4 w-1/2">
                        
                        <FormControl 
                            variant="outlined"
                            className="w-full"
                        >
                            <InputLabel id="detection-label-eng" className="bg-white">Визначення</InputLabel>
                            <Select
                                labelId="detection-label-eng"
                                name='fieldDetectionUKR'
                                value={state.fieldDetectionUKR}
                                onChange={handleDetection}
                            >
                                <MenuItem value="негативний">негативний</MenuItem>
                                <MenuItem value="позитивний">позитивний</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="relative mb-4 w-1/2">
                        
                        <FormControl 
                            variant="outlined"
                            className="w-full"
                        >
                            <InputLabel id="detection-label-eng" className="bg-white">Detection</InputLabel>
                            <Select
                                labelId="detection-label-eng"
                                name="fieldDetection"
                                onChange={handleDetection}
                                value={state.fieldDetection}
                            >
                                <MenuItem value="negative">negative</MenuItem>
                                <MenuItem value="positive">positive</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default FormAdd
