import { TextField } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'

import { store } from '../../store';

const FullTextFilter = () => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const [search, setSearch] = useState(state.filterFullText)

    useEffect(() => {
        setSearch(state.filterFullText)    
    }, [state.filterFullText])

    const handleKeyDown = (e) => {
        if (e.key==='Enter') {
            dispatch({ type: `SET_FULLTEXT`, payload: e.target.value });
        }
    }

    const handleChange = (e) => {
        setSearch(e.target.value)
    }

    return (
        <TextField 
            name="filterFullText"
            value={search}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            label="Пошуковий рядок"
            className="w-full"
            variant="outlined" 
        />
    )
}

export default FullTextFilter
