import React, { useContext } from 'react'
import {Switch, Route, Redirect} from 'react-router'

import Home from './components/homepage.jsx'
import Cert from './components/cert.jsx'
import Complete from './components/complete.jsx'
import Login from './components/login/index.jsx'
import Pdf from './components/pdfviewer/pdf.jsx'
import { store } from './store/index.jsx'
// import getAuthClient from './api/auth.js'
import Dashboard from './components/dashboard/dashboard.jsx'

// const auth = getAuthClient();

function PrivateRoute({ children, ...rest }) {
    // const [isAuthenticated, setIsAuthenticated] = useState(state.isAuthenticated)
    const globalState = useContext(store);
    const { state } = globalState;

    // useEffect(async() => {
    //   console.log(state.isAuthenticated);
    //   setIsAuthenticated(true)
    // }, [state.isAuthenticated])

    return (
        <Route
          {...rest}
          render={({ location }) =>
            state.isAuthenticated ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location }
                }}
              />
            )
          }
        />
    );
}

export default (
    <Switch>
        <Route path="/pdf/:id">
            <Pdf />
        </Route>
        <PrivateRoute PrivateRoute path="/dashboard">
          
          <Home />
        </PrivateRoute>
        <Route path="/login">
            <Login />
        </Route>
        <PrivateRoute PrivateRoute path="/complete/:id">
            <Complete />
        </PrivateRoute>
        <Route path="/cert/:id">
            <Cert />
        </Route>
        <PrivateRoute PrivateRoute path="/">
          <Dashboard />
        </PrivateRoute>
    </Switch>
)