export const defaultConfig = {
    base: 'https://api.obllik.ck.ua',
    client_id: '664a156b-b078-4bab-a31f-599d72284c73',
    operator_id: 'd6da9051-9641-4b6e-924b-4c374983e282',
    client_secret: 'Gfhjkm7918yffgb',
    operator_secret: 'Gfhjkm7918yffgb',
    rest_id: 'restapi_client',
    rest_sp: '!qaz@ws3e!qaz@ws3e',
    scope: 'restapi',
    expire_margin: 1
}